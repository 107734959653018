import styledCmp from 'styled-components'  
 
import { styled } from '@mui/material/styles'; 

import Checkbox from '@mui/material/Checkbox'; 
import Radio from '@mui/material/Radio'; 

export const MaterialCheckbox = styled(Checkbox)(({ theme }) => ({  
}));

export const MaterialRadio = styled(Radio)(({ theme }) => ({  
}));
 