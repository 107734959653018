import { toast } from "react-toastify";

export default function ErrorToast({ message }) {
  toast.error(message, {
    icon: () => (
      <img src="/icons/info-white-thin.svg" width={"24px"} height={"24px"} />
    ),
    closeButton: false,
    theme: "colored",
    hideProgressBar: true,
    style: {
      width: "312px",
      height: "56px",
      background: "#FD2C5E",
      fontSize: "16px",
    },
  });
}
