import styled from "styled-components";

export const CardGalleryBodyStatus = styled.div.attrs({})`
  max-width: 127px;
  padding: 4px 16px 4px 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: ${(props) =>
    props.isSuccess
      ? props.theme.palette.colors.greenLight
      : props.theme.palette.colors.redLight};
  border-radius: 32px;
`;
export const CardGalleryBodyStatusIcon = styled.img.attrs((p) => ({
  width: 16,
  src: `/icons/${p.icon}.svg`,
}))``;
export const CardGalleryBodyStatusText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) =>
    props.red
      ? props.theme.palette.error.main
      : props.theme.palette.colors.green};
`;

export const CardGalleryBodyStatusSpacer = styled.div.attrs({})`
  height: 21px;
`;
