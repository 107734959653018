import React, { useContext, useEffect, useRef } from "react";

import {
  CardGalleryContent,
  CardGalleryImage,
  CardGalleryBody,
  CardGalleryBodyText,
  CardGalleryBodyActions,
  CardGalleryVideo,
} from "./styled";

import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { parseStrapiImage } from "utils";
import CardApproved from "../CardApproved";

export default function CardGallery({ campaign, item, reload }) {
  const { setModal } = useContext(CoreContext);

  const openChat = () => {
    setModal({
      type: "chat",
      item: {
        ...item,
        campaignId: campaign.id,
      },
      reload,
    });
  };

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Seek to 10 seconds
      videoRef.current.currentTime = 10;
      // Start playback
      videoRef.current.play();
    }
  }, []);

  return (
    <>
      <CardGalleryContent>
        {item?.isVideo ? (
          <>
            <CardGalleryVideo
              ref={videoRef}
              controls={false}
              autoPlay={false}
              muted
              loop
            >
              <source
                src={parseStrapiImage(item?.file?.url)}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </CardGalleryVideo>
          </>
        ) : (
          <CardGalleryImage image={item?.file?.url} />
        )}
        <CardGalleryBody>
          {item?.approved === undefined || item?.approved === null ? null : (
            <CardApproved approved={item?.approved} />
          )}
          <CardGalleryBodyText>{item?.file?.name}</CardGalleryBodyText>
          <CardGalleryBodyActions>
            <Button
              primary
              outline
              onClick={
                item?.file?.url
                  ? () =>
                      window.open(
                        parseStrapiImage(item?.file?.url),
                        "campaing-asset"
                      )
                  : null
              }
            >
              DOWNLOAD
            </Button>
            <Button primary onClick={openChat}>
              VISUALIZAR
            </Button>
          </CardGalleryBodyActions>
        </CardGalleryBody>
      </CardGalleryContent>
    </>
  );
}
