import React, { useState } from "react";

export default function CreatorGaleryCard({
  influencer,
  clickable,
  selected,
  onClick,
}) {
  const [hover, setHover] = useState();

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    borderRadius: "16px",
    border: "1px solid rgba(204, 196, 216, 1)",
    marginTop: "16px",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.16)",
    ...(clickable && {
      cursor: "pointer",
    }),
    ...(hover && {
      border: "1px solid rgba(175, 0, 227, 1)",
    }),
    ...(selected && {
      border: "1px solid rgba(175, 0, 227, 1)",
    }),
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h6
              style={{
                fontSize: "24px",
                fontWeight: 700,
                margin: "0px 0px 4px",
                marginRight: "8px",
              }}
            >
              {influencer.name}
            </h6>
            <p style={{ fontSize: "16px" }}>{influencer.profile}</p>
          </div>
          <img
            src={influencer.imageUrl || "/icons/menu4-off.svg"}
            width={"64px"}
            height={"64px"}
            style={{
              borderRadius: "32px",
              marginRight: "8px",
            }}
          ></img>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "8px", alignItems: "start" }}>
            {influencer.socialNetworks.instagram && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  border: "1.07px solid rgba(204, 196, 216, 1)",
                }}
                onClick={() =>
                  window.open(influencer.socialNetworks.instagram.link)
                }
              >
                <img
                  src={"/images/insta-grey.png"}
                  width={"16px"}
                  height={"16px"}
                ></img>
              </div>
            )}

            {influencer.socialNetworks.tiktok && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  border: "1.07px solid rgba(204, 196, 216, 1)",
                }}
                onClick={() =>
                  window.open(influencer.socialNetworks.tiktok.link)
                }
              >
                <img
                  src={"/images/tiktok-grey.png"}
                  width={"16px"}
                  height={"16px"}
                ></img>
              </div>
            )}

            {influencer.socialNetworks.youtube && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "32px",
                  height: "32px",
                  borderRadius: "32px",
                  border: "1.07px solid rgba(204, 196, 216, 1)",
                }}
                onClick={() =>
                  window.open(influencer.socialNetworks.youtube.link)
                }
              >
                <img
                  src={"/icons/galery.svg"}
                  width={"16px"}
                  height={"16px"}
                ></img>
              </div>
            )}
          </div>
          <div style={{ display: "flex", gap: "24px" }}>
            <div style={{ marginBottom: "10px", marginTop: "16px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={"/images/heart.png"}
                    width={"17px"}
                    height={"16px"}
                    style={{ marginRight: "4px" }}
                  ></img>
                  <p style={{ fontSize: "16px", margin: 0 }}>Seguidores</p>
                </div>
                <p
                  style={{
                    fontSize: "32px",
                    fontWeight: 700,
                    margin: 0,
                    background:
                      "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                    "-webkit-text-fill-color": "transparent",
                    "-webkit-background-clip": "text",
                  }}
                >
                  {numberToK(influencer?.totalFollowers)}
                </p>
              </div>
            </div>

            {!influencer.totalViews ? null : (
              <div style={{ marginBottom: "10px", marginTop: "16px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={"/images/button-play.png"}
                      width={"17px"}
                      height={"16px"}
                      style={{ marginRight: "4px" }}
                    ></img>
                    <p style={{ fontSize: "16px", margin: 0 }}>Views</p>
                  </div>
                  <p
                    style={{
                      fontSize: "32px",
                      fontWeight: 700,
                      margin: 0,
                      background:
                        "linear-gradient(90.02deg, #AF00E3 11.75%, #FF00D6 80.59%, #FF00D6 81.46%)",
                      "-webkit-text-fill-color": "transparent",
                      "-webkit-background-clip": "text",
                    }}
                  >
                    {numberToK(influencer?.totalViews)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function numberToK(number) {
  return number >= 1e6
    ? `${Math.floor(number / 1e6)}M`
    : number >= 1e3
    ? `${Math.floor(number / 1e3)}K`
    : number;
}
