import styled from "styled-components";

export const CardEmptyContainer = styled.div.attrs({})`
  background: ${(p) => p.theme.palette.colors.white};
  box-shadow: 0px 3px 6px ${(p) => p.theme.palette.colors.shadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 40px 32px;
  border-radius: 16px;
  margin: 16px 0 0 0;
`;
export const CardEmptyImage = styled.img.attrs({
  src: "/images/lary.png",
})`
  width: 100%;
  max-width: 330px;
  height: 330px;
  margin: 0 0 32px;
`;
export const CardEmptyTitle = styled.div.attrs({})`
  font-size: 20px;
  color: ${(props) => props.theme.palette.colors.black};
  margin: 0 0 12px;
`;
export const CardEmptyText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.text};
  text-align: center;
  max-width: 800px;
  margin: 0 0 32px;
`;
export const CardExpandContainerHeaderLabel = styled.div.attrs({})`
  align-self: end;
  padding: 4px 8px;
  border-radius: 32px;
  background: ${(props) => props.theme.palette.colors.blue};

  display: flex;
  align-items: center;
  gap: 8px;
`;
export const CardExpandContainerHeaderLabelDecoration = styled.div.attrs({})`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${(props) => props.theme.palette.colors.white};
`;
export const CardExpandContainerHeaderLabelText = styled.div.attrs({})`
  font-size: 14px;
  color: ${(props) => props.theme.palette.colors.white};
`;
