import React, { useContext } from "react";
import { ThemedComponent } from "ui/theme";
import ModalReviewApprove from "./ReviewApprove";
import { CoreContext } from "context/CoreContext";
import ModalChat from "./Chat";

export default function ModalController() {
  const { modal } = useContext(CoreContext);
  return (
    <>
      <ThemedComponent>
        {modal?.type === "review" ? <ModalReviewApprove /> : null}
        {modal?.type === "chat" ? <ModalChat /> : null}
      </ThemedComponent>
    </>
  );
}
