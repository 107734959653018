import React, { useContext } from "react";

import {
  AssetsContent,
  AssetsContentItem,
  AssetsContentItemContent,
  AssetsContentItemIcon,
  AssetsContentItemText,
  CardHomeContentLabel,
  CardHomeContentLabelDecoration,
  CardHomeContentLabelText,
  Container,
} from "./styled";

import { Update } from "services/campaigns";
import { CoreContext } from "context/CoreContext";
import moment from "moment";
import SuccessToast from "components/Toasts/Success";
import ErrorToast from "components/Toasts/Error";

export default function CardScriptList({ campaign, scripts, reload }) {
  const { user, setModal } = useContext(CoreContext);

  const close = () => {
    setModal(null);
  };

  const approveScript = async (script) => {
    const scripts = campaign?.scripts;

    await Update(
      {
        data: {
          scripts: {
            ...scripts,
            [script.id]: {
              ...script,
              approved: true,
              approvedBy: user,
              approvedAt: new Date(),
            },
          },
        },
      },
      campaign.id
    );

    SuccessToast({ message: "Roteiro aprovado" });

    reload();
    close();
  };

  const reproveScript = async (script) => {
    const scripts = campaign?.scripts;

    await Update(
      {
        data: {
          scripts: {
            ...scripts,
            [script.id]: {
              ...script,
              approved: false,
              reprovedBy: user,
              reprovedAt: new Date(),
            },
          },
        },
      },
      campaign.id
    );

    ErrorToast({ message: "Roteiro reprovado" });

    reload();
    close();
  };

  return (
    <>
      <Container>
        <AssetsContent>
          {Object.entries(scripts).map(([id, item], key) => (
            <AssetsContentItem key={key}>
              <AssetsContentItemContent>
                <AssetsContentItemIcon />
                <AssetsContentItemText onClick={() => window.open(item.link)}>
                  {item?.title}
                </AssetsContentItemText>
              </AssetsContentItemContent>

              <StatusLabel script={item} />

              {item.approved !== null && item.approved !== undefined && (
                <p style={{ margin: 0, fontSize: "12px", marginLeft: "5px" }}>
                  Por{" "}
                  {item.approved ? item.approvedBy.name : item.reprovedBy.name}{" "}
                  {item.approved
                    ? moment(item.approvedAt).format("DD/MM/YYYY")
                    : moment(item.reprovedAt).format("DD/MM/YYYY")}{" "}
                  às{" "}
                  {item.approved
                    ? moment(item.approvedAt).format("HH:mm")
                    : moment(item.reprovedAt).format("HH:mm")}
                </p>
              )}

              {(item.approved === undefined || item.approved === null) && (
                <>
                  <CardHomeContentLabel
                    isSuccess={true}
                    style={{ cursor: "pointer" }}
                    onClick={() => approveScript(item)}
                  >
                    <CardHomeContentLabelDecoration isSuccess={true} />
                    <CardHomeContentLabelText isSuccess={true}>
                      Aprovar
                    </CardHomeContentLabelText>
                  </CardHomeContentLabel>
                  <CardHomeContentLabel
                    isSuccess={false}
                    style={{ cursor: "pointer" }}
                    onClick={() => reproveScript(item)}
                  >
                    <CardHomeContentLabelDecoration isSuccess={false} />
                    <CardHomeContentLabelText isSuccess={false}>
                      Reprovar
                    </CardHomeContentLabelText>
                  </CardHomeContentLabel>
                </>
              )}
            </AssetsContentItem>
          ))}
        </AssetsContent>
      </Container>
    </>
  );
}

function StatusLabel({ script }) {
  return (
    script.approved !== undefined &&
    script.approved !== null && (
      <CardHomeContentLabel
        isSuccess={script.approved}
        color={script.approved ? "greenLight" : "redLight"}
      >
        <CardHomeContentLabelDecoration isSuccess={script.approved} />
        <CardHomeContentLabelText isSuccess={script.approved}>
          {script.approved === undefined || script.approved === null
            ? "Em aprovação"
            : script.approved
            ? "Aprovado"
            : "Reprovado"}
        </CardHomeContentLabelText>
      </CardHomeContentLabel>
    )
  );
}
