import React, { useContext } from "react";

import {
  UploadLabel,
  AssetsContent,
  AssetsContentItem,
  AssetsContentItemContent,
  AssetsContentItemText,
  AssetsContentItemAction,
  AssetsContentItemActionIcon,
  AssetsContentItemActionIconDownload,
} from "./styled";
import { parseStrapiImage } from "utils";
import { CoreContext } from "context/CoreContext";
import CardApproved from "../CardApproved";
import ErrorToast from "components/Toasts/Error";

export default function CardAssets({
  campaign,
  title,
  removable,
  showableAssets,
  reload,
  removeAsset: removeAssetUp,
  toReview = false,
  toCampaignCreation = false,
}) {
  const { assets, removeAsset, reloading, setModal } = useContext(CoreContext);

  return !(showableAssets || assets)?.length ? null : (
    <>
      {!title ? null : <UploadLabel>{title}</UploadLabel>}

      <AssetsContent>
        {reloading
          ? null
          : (showableAssets || assets)?.map((item, key) => (
              <AssetsContentItem key={key}>
                <AssetsContentItemContent>
                  <img
                    src={
                      toCampaignCreation
                        ? item.url
                        : [".pdf", ".docx", ".xlsx", ".csv"].includes(
                            item.file?.ext
                          )
                        ? "/icons/document.svg"
                        : item.file?.previewUrl || item.file?.url
                    }
                    width="38px"
                    height="38px"
                    style={{ borderRadius: "12px" }}
                  />

                  <AssetsContentItemText>
                    {toCampaignCreation ? item.name : item?.file?.name}
                  </AssetsContentItemText>
                </AssetsContentItemContent>

                {toReview ? (
                  item?.approved === undefined || item?.approved === null ? (
                    <div
                      style={{
                        fontWeight: 500,
                        color: "#5339E3",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setModal({
                          type: "review",
                          item: {
                            ...item,
                            campaignId: campaign.id,
                          },
                          reload,
                        })
                      }
                    >
                      Avaliar entregável
                    </div>
                  ) : (
                    <CardApproved approved={item?.approved} />
                  )
                ) : null}

                <AssetsContentItemAction
                  onClick={() =>
                    !item?.file?.url
                      ? null
                      : window.open(
                          parseStrapiImage(item?.file?.url),
                          "campaing-asset"
                        )
                  }
                >
                  <AssetsContentItemActionIconDownload />
                </AssetsContentItemAction>
                {!removable && !removeAssetUp ? null : (
                  <AssetsContentItemAction
                    onClick={async () => {
                      removeAssetUp
                        ? await removeAssetUp(item?.id)
                        : await removeAsset(item?.id);

                      ErrorToast({ message: `${item?.file?.name} removido` });

                      if (reload) {
                        reload();
                      }
                    }}
                  >
                    <AssetsContentItemActionIcon />
                  </AssetsContentItemAction>
                )}
              </AssetsContentItem>
            ))}
      </AssetsContent>
    </>
  );
}
