import React from "react";

import {
  CardEmptyContainer,
  CardEmptyImage,
  CardEmptyTitle,
  CardEmptyText,
  CardExpandContainerHeaderLabel,
  CardExpandContainerHeaderLabelDecoration,
  CardExpandContainerHeaderLabelText,
} from "./styled";

export default function ScriptEmpty() {
  return (
    <>
      <CardEmptyContainer>
        <CardExpandContainerHeaderLabel>
          <CardExpandContainerHeaderLabelDecoration />
          <CardExpandContainerHeaderLabelText>
            Envio em até 5 dias
          </CardExpandContainerHeaderLabelText>
        </CardExpandContainerHeaderLabel>
        <CardEmptyImage />
        <CardEmptyTitle>Seu roteiro está sendo desenvolvido</CardEmptyTitle>
        <CardEmptyText>
          Estamos desenvolvendo o seu roteiro, em breve você vai conseguir
          acessar esses materiais nessa seção. Vamos te notificar via e-mail ou
          pela nossa plataforma NiceHouse For Business.
        </CardEmptyText>
      </CardEmptyContainer>
    </>
  );
}
