import React from "react";

import {
  CardEmptyContainer,
  CardEmptyImage,
  CardEmptyTitle,
  CardEmptyText,
  CardExpandContainerHeaderLabel,
  CardExpandContainerHeaderLabelDecoration,
  CardExpandContainerHeaderLabelText,
} from "./styled";

export default function CreatorsEmpty() {
  return (
    <>
      <CardEmptyContainer>
        <CardExpandContainerHeaderLabel>
          <CardExpandContainerHeaderLabelDecoration />
          <CardExpandContainerHeaderLabelText>
            Envio em até 3 dias
          </CardExpandContainerHeaderLabelText>
        </CardExpandContainerHeaderLabel>
        <CardEmptyImage />
        <CardEmptyTitle>Estamos selecionando seus criadores!</CardEmptyTitle>
        <CardEmptyText>
          Estamos escolhendo a sua seleção de criadores, em breve você vai
          conseguir acessar esses materiais nessa seção.Vamos te notificar via
          e-mail ou pela nossa plataforma NiceHouse For Business.
        </CardEmptyText>
      </CardEmptyContainer>
    </>
  );
}
